import React, { useState } from 'react'
import { Script } from 'gatsby'
import cookie from 'react-cookies'
import Layout from '../../../components/employerDashboard/layout'
import ResumeSearchComponent from '../../../components/employerDashboard/resume/search'
import { GOOGLE_API_KEY } from '../../../config'

const ResumeSearchPage = ({ location }) => {
  const [googleAPILoaded, setGoogleAPILoaded] = useState(false)

  return (
    <Layout title="Resume Search | MedReps.com" location={location}>
      {googleAPILoaded ? (
        <ResumeSearchComponent />
      ) : (
        <Script
          type="text/javascript"
          src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`}
          onLoad={() => setGoogleAPILoaded(true)}
        />
      )}
    </Layout>
  )
}
export default ResumeSearchPage
