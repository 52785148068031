import React, { useState } from 'react'
import { dateFormatHandler, displayValueHandler } from '../../../functions'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'

const ResumeCardFunction = ({ title, data }) => {
  const [viewMore, setViewMore] = useState(false)

  const isViewMore = data.length > 3 ? true : false

  return (
    <div>
      <div className="text-[#232323] mb-2 text-sm font-semibold">{title}</div>
      {!viewMore && (
        <div>
          {data.slice(0, 3).map(dataItem => (
            <div className="text-[#756F63] text-xs">{dataItem}</div>
          ))}
          {isViewMore && (
            <div
              className="text-[#6D1E36] text-xs font-bold"
              onClick={() => setViewMore(true)}
            >
              More
            </div>
          )}
        </div>
      )}
      {viewMore && (
        <div>
          {data.map(dataItem => (
            <div className="text-[#756F63] text-xs">{dataItem}</div>
          ))}
        </div>
      )}
    </div>
  )
}

const ResumeCard = ({ data, onView, onSave, onUnsave }) => {
  return (
    <div className="px-5 py-7 border-b border-[#DCE9EE]">
      <div className="flex justify-between items-center">
        <div className="text-[#232323] font-semibold text-sm">Name</div>
        <div>
          <button
            className="px-3.5 py-1 bg-[#E5EDF0] text-[#00567C] rounded text-xs font-bold mr-1"
            onClick={onView}
          >
            View
          </button>
          {!data?.saved ? (
            <button
              className="px-3.5 py-1 bg-[#E5EDF0] text-[#00567C] rounded text-xs font-bold"
              onClick={onSave}
            >
              Save
            </button>
          ) : (
            <button
              className="px-3.5 py-1 bg-[#E5EDF0] text-[#00567C] rounded text-xs font-bold"
              onClick={onUnsave}
            >
              Unsave
            </button>
          )}
        </div>
      </div>
      <div className="flex items-center my-5">
        {data?.image?.url ? (
          <div
            className="h-8 w-8 bg-no-repeat bg-cover"
            style={{
              backgroundImage: `url(${data?.image?.url})`,
            }}
          />
        ) : (
          <Avatar
            className="flex items-center justify-center"
            icon={<UserOutlined style={{ color: '#00567C' }} />}
          />
        )}
        {/* <div className="w-8 h-8 bg-[#DCE9EE] rounded-full"></div> */}
        <div className="ml-4 text-[#232323] text-sm font-semibold">
          {data?.name}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-4 gap-y-6">
        <ResumeCardFunction
          title="Function"
          data={
            data.job_functions?.length
              ? data.job_functions.map((item, index) => {
                  return (
                    displayValueHandler(item) +
                    (index !== data.job_functions.length - 1 ? ', ' : '')
                  )
                })
              : ['Not Specified']
          }
        />
        <ResumeCardFunction
          title="industry"
          data={
            data?.industries?.length
              ? data.industries.map((item, index) => {
                  return (
                    displayValueHandler(item) +
                    (index !== data.industries.length - 1 ? ', ' : '')
                  )
                })
              : ['Not Specified']
          }
        />
        <div>
          <div className="text-[#232323] mb-2 text-sm font-semibold">
            Location
          </div>
          <div className="text-[#756F63] text-xs">{data?.address}</div>
        </div>
        <div>
          <div className="text-[#232323] mb-2 text-sm font-semibold">
            Last Updated
          </div>
          <div className="text-[#756F63] text-xs">
            {data?.defaultResumeCreatedAt
              ? dateFormatHandler(data?.defaultResumeCreatedAt)
              : ''}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResumeCard
