import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, message } from 'antd'
import axios from 'axios'
import { Link, navigate } from 'gatsby'
import cookie from 'react-cookies'
import { permissionsHandler, returnCreditType } from '../../../functions'
import globalData from '../../../../globalData.json'
import { strapiURL } from '../../../config'

const ResumeSearchDisabledModal = ({ visible, user, userProfile }) => {
  const storeProducts = useSelector(state => state.user.products)

  let products = permissionsHandler(storeProducts, user?.role?.id)
  let subscription = products?.subscriptionPurchased

  const [disabledSubscription, setDisabledSubscription] = useState(false)
  const [checkoutLoading, setCheckoutLoading] = useState(false)

  useEffect(() => {
    let employerSubscriptionItems = globalData?.EmployerCatalog?.prices || []
    if (subscription) {
      setDisabledSubscription(
        employerSubscriptionItems?.find(
          item => item?.price?.priceId === subscription?.price?.priceId
        )?.isDiscontinued
      )
    }
  }, [])

  const handlePurchase = () => {
    if (subscription) {
      let employerSubscriptionItems = globalData?.EmployerCatalog?.prices || []
      const activeSubscription = employerSubscriptionItems.find(
        item => item?.price?.priceId === subscription?.price?.priceId
      )
      const addonList = activeSubscription?.addOns || []

      const activeAddon = addonList.find(addon => {
        const addonType = returnCreditType(addon.credit_type).type
        if (addonType === 'resumeSearch') {
          return true
        }
        return false
      })

      if (activeAddon) {
        setCheckoutLoading(true)

        localStorage.setItem(
          'emp-subscription',
          JSON.stringify({
            subscription: null,
            addons: [
              {
                ...activeAddon,
                is_selected: true,
              },
            ],
          })
        )

        axios
          .post(strapiURL + '/stripe/session/checkout', {
            line_items: [
              {
                price: activeAddon.priceId,
                quantity: 1,
              },
            ],
            success_url:
              window.location.origin +
              '/thankyou/?success=true&session_id={CHECKOUT_SESSION_ID}',
            cancel_url: window.location.origin,
            customerEmail: userProfile?.user?.email || '',
            mode: activeAddon.recurring ? 'subscription' : 'payment',
            customer:
              user?.customer_id?.trim() ||
              userProfile?.user?.subscription?.stripe_customer_id ||
              '',
            clientId: cookie.load('_ga')?.substring(6),
            sessionId: cookie
              .load(
                process.env.GATSBY_GOOGLE_ANALYTICS_MEASUREMENT_ID?.replace(
                  'G-',
                  '_ga_'
                )
              )
              ?.split('.')[2],
          })
          .then(res => {
            setCheckoutLoading(false)
            window.location.href = res?.data?.url
          })
          .catch(err => {
            setCheckoutLoading(false)
            message.error('Something wrong while checkout.')
          })
      } else {
        navigate('/employer/membership/')
      }
    } else {
      navigate('/employer/membership/')
    }
  }

  return (
    <Modal
      width={600}
      footer={null}
      visible={visible}
      okText="Save"
      className="p-0 candidateAlertModal shadow-lg"
    >
      <div className="bg-white rounded-br px-4 pt-4 pb-8 flex flex-col items-center rounded-md">
        {disabledSubscription ? (
          <p className="font-sans text-center mb-0 text-voyage font-bold text-lg mt-2">
            {' '}
            Resume search is not available with your current plan. Please
            contact <a href="mailto:info@medreps.com">info@medreps.com</a> for
            assistance.
          </p>
        ) : (
          <p className="font-sans text-center mb-0 text-voyage font-bold text-lg mt-2">
            You do not have active Resume Search Access. To purchase, please
            purchase a MedReps Unlimited Solution and add Resume Search to your
            plan.
          </p>
        )}

        <div className="flex items-center justify-center mt-6 w-11/12 lg:w-[55%] mx-auto">
          {!disabledSubscription && (
            <button
              onClick={handlePurchase}
              className="text-white py-4 px-10 bg-voyage cursor-pointer font-sans font-bold rounded mr-8"
            >
              {checkoutLoading ? 'Purchasing...' : 'Purchase'}
            </button>
          )}

          <Link to="/employer-dashboard">
            <button className="bg-bgSecondaryButton text-voyage sm:w-auto text-sm font-bold font-sans py-4 px-10 rounded">
              Cancel
            </button>
          </Link>
        </div>
      </div>
    </Modal>
  )
}

export default ResumeSearchDisabledModal
