import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Form, Input, message, Table, Spin, Avatar, Pagination } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import axios from 'axios'
import saveAs from 'file-saver'
import JSZipUtils from 'jszip-utils'
import EmployerSaveSearchModal from '../../Modals/employerSaveSearch'
import { ArrowIcon } from '../../icons'
import { isNil, isBoolean } from 'lodash'
import {
  permissionsHandler,
  dateFormatHandler,
  displayValueHandler,
  getDecodedLocation,
  serialize,
} from '../../../functions'
import { strapiURL } from '../../../config'
import SearchFilterForm from './searchFilterForm'
import {
  RESUME_SEARCH_MEDICAL_EXPERIENCE_OPTION,
  RESUME_SEARCH_TOTAL_EXPERIENCE_OPTION,
  RESUME_SEARCH_WILLING_TRAVEL_OPTION,
  RESUME_SEARCH_SORT_BY_RELEVANCY,
  RESUME_SEARCH_SORT_BY_DATE,
} from '../../../data'
import EmployerResumePreview from '../../Modals/employerResumePreview'
import ResumeCard from './resumeCard'
import cookie from 'react-cookies'
import ResumeSearchDisabledModal from './resumeSearchDisabledModal'
import useGMT from '../../../hooks/useGTM'

const DEFAULT_FILTERS = {
  general_sales_experience_years_from: RESUME_SEARCH_TOTAL_EXPERIENCE_OPTION[0],
  general_sales_experience_years_to: RESUME_SEARCH_TOTAL_EXPERIENCE_OPTION[1],
  medical_sales_experience_years_from:
    RESUME_SEARCH_MEDICAL_EXPERIENCE_OPTION[0],
  medical_sales_experience_years_to: RESUME_SEARCH_MEDICAL_EXPERIENCE_OPTION[1],
  willing_travel_percentage_from: RESUME_SEARCH_WILLING_TRAVEL_OPTION[0],
  willing_travel_percentage_to: RESUME_SEARCH_WILLING_TRAVEL_OPTION[1],
}

const ResumeSearchComponent = ({ userProfile, location, user }) => {
  const [initializing, setInitializing] = useState(true)
  const [showFilter, setShowFilter] = useState(true)
  const [resumes, setResumes] = useState([])
  const [submitLoading, setSubmitLoading] = useState(true)
  const [resumeUnlockLoading, setResumeUnlockLoading] = useState(null)
  const [multipleResume, setMultipleResume] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [keywordForm] = Form.useForm()
  const [filterForm] = Form.useForm()
  const storeProducts = useSelector(state => state.user.products)
  const [keyword, setKeyword] = useState('')
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  const [changedFilters, setChangedFilters] = useState(DEFAULT_FILTERS)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(null)
  const [savedProfiles, setSavedProfiles] = useState({})
  const [sortOption, setSortOption] = useState(RESUME_SEARCH_SORT_BY_RELEVANCY)

  const [width, setWidth] = useState(window?.innerWidth)
  const [prevOpen, setPrevOpen] = useState(false)
  const [selectCandidate, setSelectCandidate] = useState(null)
  const [selectCandidateIndex, setSelectCandidateIndex] = useState(0)

  const {
    pushSearchResumeEvent,
    pushFilterApplyEvent,
    getResumeSearchCriteriaStr,
  } = useGMT()

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleWindowSizeChange)
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange)
      }
    }
  }, [])

  let products = permissionsHandler(storeProducts, user?.role?.id)

  useEffect(() => {
    setInitializing(true)
    const runningSearch = location?.state?.runningSearch
    if (runningSearch) {
      let filters = {
        distance: runningSearch.distance,
        education_level: runningSearch.education_level,
        functions: runningSearch.functions,
        industries: runningSearch.industries,
        is_1099: runningSearch.is_1099,
        relocate: runningSearch.relocate,
        general_sales_experience_years_from:
          runningSearch.general_sales_experience_years_from ||
          RESUME_SEARCH_TOTAL_EXPERIENCE_OPTION[0],
        general_sales_experience_years_to:
          runningSearch.general_sales_experience_years_to ||
          RESUME_SEARCH_TOTAL_EXPERIENCE_OPTION[1],
        medical_sales_experience_years_from:
          runningSearch.medical_sales_experience_years_from ||
          RESUME_SEARCH_MEDICAL_EXPERIENCE_OPTION[0],
        medical_sales_experience_years_to:
          runningSearch.medical_sales_experience_years_to ||
          RESUME_SEARCH_MEDICAL_EXPERIENCE_OPTION[1],
        willing_travel_percentage_from:
          runningSearch.willing_travel_percentage_from ||
          RESUME_SEARCH_WILLING_TRAVEL_OPTION[0],
        willing_travel_percentage_to:
          runningSearch.willing_travel_percentage_to ||
          RESUME_SEARCH_WILLING_TRAVEL_OPTION[1],
        location: runningSearch.location?.placePredict,
      }

      setFilters(filters)
      setKeyword(runningSearch.keyword)
      keywordForm.setFieldsValue({
        keyword: runningSearch?.keyword || '',
      })
    } else {
      setFilters(DEFAULT_FILTERS)
    }
    setInitializing(false)
  }, [location?.state])

  useEffect(() => {
    const runningSearch = location?.state?.runningSearch
    if (runningSearch) {
      setSubmitLoading(true)
      if (runningSearch.id) {
        try {
          axios.get(
            strapiURL +
              `/candidate-profiles/employer/search/update?search_id=${runningSearch.id}`
          )
        } catch (e) {
          message.error('Something went wrong.')
          setSubmitLoading(false)
        }
      }
    }
  }, [location?.state])

  const saveResumeHandler = async (record, status) => {
    setResumeUnlockLoading(record?.id)
    try {
      let currentUser = cookie.load('user')
      const result = await axios.post(
        strapiURL +
          `/employer-saved-resumes/${userProfile?.id}/setSavedProfiles`,
        {
          candidate_profile: record.objectID,
          saved_by: currentUser?.user?.id,
          saveStatus: status,
        }
      )

      const savedProfileData = {}
      result?.data?.forEach(item => {
        savedProfileData[item.candidate_profile.id] = item
      })

      setSavedProfiles(savedProfileData)
      if (status) {
        message.success('Resume Saved')
      } else {
        message.success('Resume unsaved')
      }
      setResumeUnlockLoading(false)
    } catch (error) {
      message.error('Something went wrong.')
      setResumeUnlockLoading(false)
    }
  }

  const handleNextProfile = async () => {
    const selectedIndex = resumes.findIndex(
      item => item.objectID == selectCandidate
    )

    if (selectedIndex < resumes.length - 1) {
      setSelectCandidate(resumes[selectedIndex + 1].objectID)
      setSelectCandidateIndex(selectedIndex + 1)
      handleResumeViewCount(resumes[selectedIndex + 1].objectID)
      return
    }

    if (page * 20 < total) {
      const result = await handleSearchResumes(page + 1)
      setSelectCandidate(result[0].objectID)
      setSelectCandidateIndex(0)
      setPage(page + 1)
      handleResumeViewCount(result[0].objectID)
      return
    }
  }

  const handlePrevProfile = async () => {
    const selectedIndex = resumes.findIndex(
      item => item.objectID == selectCandidate
    )
    if (selectedIndex > 0) {
      setSelectCandidate(resumes[selectedIndex - 1].objectID)
      setSelectCandidateIndex(selectedIndex - 1)
      handleResumeViewCount(resumes[selectedIndex - 1].objectID)
      return
    }

    if (page > 1) {
      const result = await handleSearchResumes(page - 1)
      setSelectCandidate(result[result?.length - 1].objectID)
      setSelectCandidateIndex(result?.length - 1)
      setPage(page - 1)
      handleResumeViewCount(result[result?.length - 1].objectID)
      return
    }
  }

  const viewResume = async (candidateId, index) => {
    setSelectCandidate(candidateId)
    setSelectCandidateIndex(index)
    setPrevOpen(true)
    handleResumeViewCount(candidateId)
  }

  const handleResumeViewCount = async candidateId => {
    let currentUser = cookie.load('user')
    try {
      const result = await axios.post(strapiURL + '/resume-views', {
        employer: userProfile.id,
        candidate_profile: candidateId,
        user: currentUser?.user?.id,
        type: 'view',
      })
    } catch (error) {
      console.log('error ---', error)
    }
  }

  const downloadMultiple = () => {
    const zip = require('jszip')()
    var count = 0
    var zipFilename = 'resumes.zip'

    let urls = multipleResume.map(
      item => strapiURL + item.default_resume.parsed_resume
    )
    urls.forEach(function (url) {
      let fileSplit = url.split('/')
      var filename = fileSplit[fileSplit?.length - 1]
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          throw err // or handle the error
        }
        zip.file(filename, data, { binary: true })
        count++
        if (count == urls.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, zipFilename)
          })
        }
      })
    })
    setMultipleResume([])
  }

  const handleKeywordSubmit = values => {
    filterForm.submit()
    pushFiltersToGTM({ ...(filters || {}) })
  }

  const handleSearchResumes = async pageNum => {
    const result = await axios.post(strapiURL + '/employers/resumes/search', {
      keyword,
      page: pageNum - 1,
      ...filters,
    })
    return result?.data?.data || []
  }

  const searchResumes = async () => {
    setResumes([])
    setSubmitLoading(true)
    let searchFilters = {
      distance: filters?.distance,
      education_level: filters?.education_level,
      functions: filters?.functions,
      industries: filters?.industries,
      is_1099: filters?.is_1099,
      relocate: filters?.relocate,
      totalExp: [
        filters?.general_sales_experience_years_from,
        filters?.general_sales_experience_years_to,
      ],
      medicalExp: [
        filters?.medical_sales_experience_years_from,
        filters?.medical_sales_experience_years_to,
      ],
      willingTravelUpto: [
        filters.willing_travel_percentage_from,
        filters.willing_travel_percentage_to,
      ],
    }

    if (
      filters?.general_sales_experience_years_from ===
        DEFAULT_FILTERS.general_sales_experience_years_from &&
      filters?.general_sales_experience_years_to ===
        DEFAULT_FILTERS.general_sales_experience_years_to
    ) {
      delete searchFilters['totalExp']
    }

    if (
      filters?.medical_sales_experience_years_from ===
        DEFAULT_FILTERS.medical_sales_experience_years_from &&
      filters?.medical_sales_experience_years_to ===
        DEFAULT_FILTERS.medical_sales_experience_years_to
    ) {
      delete searchFilters['medicalExp']
    }

    // location process //
    if (filters?.location?.value?.place_id) {
      const decodedLocation = await getDecodedLocation(
        filters?.location?.value?.place_id
      )
      if (decodedLocation) {
        searchFilters.city = decodedLocation.city
        searchFilters.state = decodedLocation.state
        searchFilters.country = decodedLocation.country
        searchFilters.geoCode = `${decodedLocation.latitude}, ${decodedLocation.longitude}`
      }
    }

    if (sortOption === RESUME_SEARCH_SORT_BY_DATE) {
      searchFilters.sort = RESUME_SEARCH_SORT_BY_DATE
    }

    axios
      .post(strapiURL + '/employers/resumes/search', {
        keyword,
        page: page - 1,
        ...searchFilters,
      })
      .then(res => {
        pushSearchResumeEvent({
          searchquery: '',
          searchType: 'hp box',
          searchCriteriaString: getResumeSearchCriteriaStr({
            ...searchFilters,
            keyword,
          }),
          'searchCriteria.numberOfResults': res.data.total || 0,
        })
        setTotal(res?.data?.total)
        let _data = [...res.data?.data]

        _data?.forEach(cp => {
          cp.key = cp.id

          if (cp.city && cp.state) {
            cp.address = cp.city + ', ' + cp.state
          } else if (cp.city && !cp.state) {
            cp.address = cp.city
          } else if (!cp.city && cp.state) {
            cp.address = cp.state
          } else {
            cp.address = 'Not Specified'
          }
        })
        setResumes(_data)
        setSubmitLoading(false)

        if (width <= 768) {
          setShowFilter(false)
        }
      })
      .catch(err => {
        message.warning('No Resume Found.Please update your search.')
        setResumes([])
        setSubmitLoading(false)
      })
  }

  useEffect(() => {
    setPage(1)
  }, [keyword, filters, sortOption])

  useEffect(() => {
    if (userProfile?.id) {
      handleLoadSavedProfiles()
    }
  }, [userProfile])

  const handleLoadSavedProfiles = async () => {
    try {
      const result = await axios.get(
        strapiURL +
          `/employer-saved-resumes/${userProfile?.id}/getSavedProfiles`
      )

      const savedProfileData = {}
      result?.data?.forEach(item => {
        savedProfileData[item.candidate_profile.id] = item
      })

      setSavedProfiles(savedProfileData)
    } catch (error) {}
  }

  useEffect(() => {
    if (!initializing) {
      searchResumes()
    }
  }, [initializing, page, filters, keyword, sortOption])

  const filterHandler = async filters => {
    setInitializing(true)
    setFilters(filters)
    setKeyword(keywordForm.getFieldValue('keyword'))
    pushFiltersToGTM({ ...(filters || {}) })
    setInitializing(false)
  }

  const onChangeFilters = filters => {
    setChangedFilters(filters)
  }

  const pushFiltersToGTM = filters => {
    if (
      filters?.general_sales_experience_years_from ===
        DEFAULT_FILTERS.general_sales_experience_years_from &&
      filters?.general_sales_experience_years_to ===
        DEFAULT_FILTERS.general_sales_experience_years_to
    ) {
      delete filters['general_sales_experience_years_from']
      delete filters['general_sales_experience_years_to']
    }

    if (
      filters?.medical_sales_experience_years_from ===
        DEFAULT_FILTERS.medical_sales_experience_years_from &&
      filters?.medical_sales_experience_years_to ===
        DEFAULT_FILTERS.medical_sales_experience_years_to
    ) {
      delete filters['medical_sales_experience_years_from']
      delete filters['medical_sales_experience_years_to']
    }

    if (
      filters?.willing_travel_percentage_from ===
        DEFAULT_FILTERS.willing_travel_percentage_from &&
      filters?.willing_travel_percentage_to ===
        DEFAULT_FILTERS.willing_travel_percentage_to
    ) {
      filters.willing_travel_percentage_from = ''
      delete filters['willing_travel_percentage_from']
      delete filters['willing_travel_percentage_to']
    }

    Object.keys(filters).forEach(function (key) {
      if (!isNil(filters[key]) && filters[key] !== '') {
        let value = filters[key]
        if (isBoolean(value) && !value) {
          return
        }

        if (Array.isArray(value)) {
          if (value.length <= 0) {
            return
          }
          let arrValues = ''
          value.map((item, index) => {
            arrValues =
              arrValues +
              displayValueHandler(item) +
              (index !== value.length - 1 ? '_' : '')
          })
          value = arrValues
        }

        if (key === 'location') {
          value = value?.label
        }

        pushFilterApplyEvent({
          filtertype: 'Resume',
          filtername: key,
          filtervalue: value,
        })
      }
    })
  }

  const resetFilters = () => {
    setKeyword('')
    keywordForm.resetFields()
    setFilters(DEFAULT_FILTERS)
    setPage(1)
  }

  const resumeCols = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
      key: 'name',
      render: (text, record, index) => {
        return (
          <div className="flex items-center">
            {record?.image?.url ? (
              <div
                className="h-8 w-8 bg-no-repeat bg-cover"
                style={{
                  backgroundImage: `url(${record?.image?.url})`,
                }}
              />
            ) : (
              <Avatar
                className="flex items-center justify-center"
                shape="square"
                icon={<UserOutlined />}
              />
            )}
            <p className="text-black font-sans font-semibold mb-0 text-last ml-2">
              {record.name}
            </p>
          </div>
        )
      },
    },
    {
      title: 'Function',
      dataIndex: 'function',
      width: '25%',
      key: 'function',
      render: (text, record, index) => {
        return (
          <p className="text-black font-sans font-normal mb-0 text-last">
            {record.job_functions?.length
              ? record.job_functions.map((item, index) => {
                  return (
                    displayValueHandler(item) +
                    (index !== record.job_functions.length - 1 ? ', ' : '')
                  )
                })
              : 'Not Specified'}
          </p>
        )
      },
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      width: '25%',
      key: 'industry',
      render: (text, record, index) => {
        return (
          <p className="text-black font-sans font-normal mb-0 text-last">
            {record.industries?.length
              ? record.industries.map((item, index) => {
                  return (
                    displayValueHandler(item) +
                    (index !== record.industries.length - 1 ? ', ' : '')
                  )
                })
              : 'Not Specified'}
          </p>
        )
      },
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: '20%',
      key: 'location',
      render: (text, record, index) => {
        return (
          <div className="flex items-center text-black font-sans font-normal mb-0 text-last">
            <svg
              className="mr-1"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.95">
                <path
                  d="M7.43945 3.73804C7.06861 3.73804 6.7061 3.848 6.39776 4.05403C6.08942 4.26006 5.84909 4.55289 5.70718 4.89551C5.56527 5.23812 5.52813 5.61512 5.60048 5.97883C5.67283 6.34254 5.8514 6.67664 6.11363 6.93886C6.37585 7.20108 6.70995 7.37966 7.07366 7.45201C7.43737 7.52436 7.81437 7.48722 8.15699 7.34531C8.4996 7.2034 8.79243 6.96307 8.99846 6.65473C9.20449 6.34639 9.31445 5.98388 9.31445 5.61304C9.31389 5.11593 9.11616 4.63934 8.76465 4.28784C8.41315 3.93633 7.93656 3.7386 7.43945 3.73804ZM7.43945 6.55054C7.25403 6.55054 7.07278 6.49555 6.91861 6.39254C6.76444 6.28952 6.64427 6.14311 6.57332 5.9718C6.50236 5.8005 6.48379 5.612 6.51997 5.43014C6.55614 5.24828 6.64543 5.08124 6.77654 4.95012C6.90765 4.81901 7.0747 4.72972 7.25656 4.69355C7.43841 4.65738 7.62691 4.67594 7.79822 4.7469C7.96953 4.81786 8.11594 4.93802 8.21896 5.09219C8.32197 5.24636 8.37695 5.42762 8.37695 5.61304C8.37667 5.86159 8.2778 6.09988 8.10205 6.27563C7.9263 6.45139 7.68801 6.55025 7.43945 6.55054Z"
                  fill="black"
                />
                <path
                  d="M11.0416 2.00733C10.1508 1.11672 8.96165 0.587656 7.70367 0.522257C6.44569 0.456859 5.20808 0.859758 4.22969 1.6532C3.25131 2.44663 2.6015 3.57436 2.4057 4.81872C2.20989 6.06309 2.48196 7.33588 3.1694 8.39144L6.7116 13.8293C6.79043 13.9503 6.89821 14.0497 7.02517 14.1186C7.15213 14.1874 7.29426 14.2234 7.43867 14.2234C7.58309 14.2234 7.72522 14.1874 7.85218 14.1186C7.97914 14.0497 8.08692 13.9503 8.16575 13.8293L11.7081 8.39144C12.3459 7.41225 12.6278 6.24356 12.5065 5.08126C12.3852 3.91895 11.868 2.83367 11.0416 2.00733ZM10.9226 7.87971L7.43869 13.2279L3.95479 7.87971C2.88839 6.24263 3.11711 4.05181 4.49863 2.67023C4.88472 2.28413 5.34308 1.97785 5.84754 1.7689C6.35199 1.55994 6.89267 1.45239 7.43869 1.45239C7.98471 1.45239 8.52539 1.55994 9.02984 1.7689C9.5343 1.97785 9.99266 2.28413 10.3787 2.67023C11.7603 4.05181 11.989 6.24263 10.9226 7.87971Z"
                  fill="black"
                />
              </g>
            </svg>
            <div className="flex-1">{record?.address}</div>
          </div>
        )
      },
    },
    {
      title: 'Last Updated',
      dataIndex: 'updated_at',
      width: '25%',
      key: 'defaultResumeCreatedAt',
      render: (text, record, index) => {
        return (
          <p className="text-black font-sans font-normal mb-0 text-last">
            {record?.defaultResumeCreatedAt
              ? dateFormatHandler(record?.defaultResumeCreatedAt)
              : ''}
          </p>
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'key',
      width: '10%',
      key: 'key',
      render: (text, record, index) => {
        return (
          <div className="flex items-center">
            <span
              onClick={() => viewResume(record.objectID, index)}
              className="font-sans text-last text-merlot mb-0 cursor-pointer"
            >
              View
            </span>

            {!record.saved ? (
              <>
                <div className="h-4 w-px bg-merlot mx-2" />
                <span
                  onClick={() => saveResumeHandler(record, true)}
                  className="font-sans text-last text-merlot mb-0 cursor-pointer"
                >
                  Save
                </span>
              </>
            ) : (
              <>
                <div className="h-4 w-px bg-merlot mx-2" />
                <p className=" mx-2 inline-block bg-[#ddeddf] text-[#059913] text-location text-last font-sans mb-0 px-2 rounded">
                  Saved
                </p>
                <span
                  onClick={() => saveResumeHandler(record, false)}
                  className="font-sans text-last text-merlot mb-0 cursor-pointer"
                >
                  Unsave
                </span>
              </>
            )}

            <div
              className={
                resumeUnlockLoading === parseInt(record.objectID)
                  ? 'opacity-100'
                  : 'opacity-0'
              }
            >
              <Spin className="action-loader ml-2" />
            </div>
          </div>
        )
      },
    },
  ]

  const isSavedProfile = useMemo(() => {
    if (selectCandidate) {
      if (savedProfiles[selectCandidate]) {
        return true
      }
      return false
    }
    return false
  }, [selectCandidate, savedProfiles])

  const isPrevDisable = useMemo(() => {
    if (total === 0) {
      return true
    }

    if (page === 1 && selectCandidateIndex === 0) {
      return true
    }

    return false
  }, [selectCandidateIndex, page, total])

  const isNextDisable = useMemo(() => {
    if (total === 0) {
      return true
    }

    if ((page - 1) * 20 + selectCandidateIndex + 1 >= total) {
      return true
    }

    return false
  }, [selectCandidateIndex, page, total])

  const dataSource = useMemo(() => {
    const data = resumes.map(resumeItem => {
      let _isSaved = savedProfiles[parseInt(resumeItem.objectID)] ? true : false

      return {
        ...resumeItem,
        saved: _isSaved,
      }
    })
    return data
  }, [resumes, savedProfiles])

  const handleSortOption = option => {
    setSortOption(option)
  }

  return (
    <>
      <ResumeSearchDisabledModal
        visible={!products.allowResumeSearch}
        user={user}
        userProfile={userProfile}
      />
      <div>
        <h1 className="font-playfair text-headingThree font-normal text-merlot">
          Resume Search
        </h1>
        <div className="flex flex-col-reverse lg:flex-row items-start lg:items-end">
          <div className="w-full flex-1">
            <Form
              form={keywordForm}
              name="create-job-form"
              layout="vertical"
              onFinish={handleKeywordSubmit}
            >
              <div className="flex-1 items-start lg:items-end flex flex-col lg:flex-row">
                <Form.Item
                  name="keyword"
                  label="Keyword"
                  className="theme-form-item w-full lg:w-2/6 mb-0"
                >
                  <Input
                    type="text"
                    placeholder="Enter keyword"
                    className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                  />
                </Form.Item>
                <div className="mt-4 ml-0 md:ml-8 md:mt-0">
                  <button className="bg-voyage py-3 px-6 text-white font-bold rounded text-sm font-sans flex items-center">
                    Search
                    <div
                      className={`${!showFilter ? 'rotate-180' : ''} ml-2`}
                    ></div>
                  </button>
                </div>
                <div className="w-full flex justify-end">
                  <button
                    onClick={() => setShowFilter(prev => !prev)}
                    type="button"
                    className="bg-bgSecondaryButton py-3 px-6 text-voyage font-bold rounded text-sm font-sans flex items-center"
                  >
                    Filter
                    <div className={`${!showFilter ? 'rotate-180' : ''} ml-2`}>
                      <ArrowIcon />
                    </div>
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
        {showFilter && (
          <div className="pt-12">
            <SearchFilterForm
              filterForm={filterForm}
              editItem={filters}
              handleFilter={filterHandler}
              handleReset={resetFilters}
              onChangeFilters={onChangeFilters}
            />
          </div>
        )}
        {!submitLoading && !resumes?.length && (
          <div className="transition-opacity border border-red-500 text-red-500 rounded-md p-2 text-left mt-4 w-max md:w-1/4">
            No matching resumes were found
          </div>
        )}
        <div className="mt-8">
          Sort by:{' '}
          <span
            className={
              sortOption === RESUME_SEARCH_SORT_BY_RELEVANCY
                ? 'font-bold pointer-events-none'
                : 'cursor-pointer text-voyage'
            }
            onClick={() => handleSortOption(RESUME_SEARCH_SORT_BY_RELEVANCY)}
          >
            Relevancy
          </span>{' '}
          -{' '}
          <span
            className={
              sortOption === RESUME_SEARCH_SORT_BY_DATE
                ? 'font-bold pointer-events-none'
                : 'cursor-pointer text-voyage'
            }
            onClick={() => handleSortOption(RESUME_SEARCH_SORT_BY_DATE)}
          >
            Last Updated
          </span>
        </div>
        <div className="rounded-lg bg-white shadow-profileCard mt-2">
          <div className="flex px-4 py-4 border-b border-bgSecondaryButton justify-between">
            <div className="flex items-center w-[30%] lg:w-fit">
              <img src="/icons/icon-application.svg" className="mb-0" />
              <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
                Results ({total || 0})
              </h1>
            </div>
            <div className="flex items-center flex-wrap w-1/2 lg:w-fit">
              {multipleResume?.length > 1 ? (
                <button
                  onClick={() => downloadMultiple()}
                  className="bg-voyage py-3 px-5 font-sans text-white text-last font-bold rounded mr-2"
                >
                  Download All
                </button>
              ) : (
                <div />
              )}

              <button
                className="bg-[#A51440] text-white py-1 rounded-md px-3 mr-2"
                type="button"
                onClick={() => setModalOpen(true)}
              >
                Create Alert
              </button>
              <Link to="/employer-dashboard/resume/searches/">
                <span className="text-sm text-jobTag underline">
                  View All Alerts
                </span>
              </Link>
              <div className="h-4 w-[0.5px] bg-jobTag mx-2" />
              <Link to="/employer-dashboard/resume/saved-resumes/">
                <span className="text-sm text-jobTag underline">
                  View Saved Resumes
                </span>
              </Link>
            </div>
          </div>
          <div className="hidden md:block">
            <Table
              loading={submitLoading}
              className="w-full hide-table-header-background hide-table-header-cols-border table-with-pagination table-with-checkbox overflow-x-scroll"
              scroll={{ x: 'min-content' }}
              columns={resumeCols}
              dataSource={dataSource}
              bordered={false}
              rowSelection={false}
              pagination={{
                total: total,
                defaultCurrent: 1,
                current: page,
                defaultPageSize: 20,
                showSizeChanger: false,
                onChange: page => setPage(page),
              }}
            />
          </div>
          <div className="block md:hidden relative">
            {resumes.map((item, index) => (
              <div key={`key-resume-item-${index}`}>
                <ResumeCard
                  data={item}
                  onView={() => viewResume(item.objectID, index)}
                  onSave={() => saveResumeHandler(item, true)}
                  onUnsave={() => saveResumeHandler(item, false)}
                />
              </div>
            ))}
            <div className="flex justify-center py-8">
              <Pagination
                pageSize={20}
                total={total}
                defaultCurrent={1}
                current={page}
                onChange={page => setPage(page)}
                showSizeChanger={false}
              />
            </div>
            {submitLoading && (
              <div className="absolute flex justify-center items-center inset-0">
                <Spin />
              </div>
            )}
          </div>
        </div>
        {modalOpen && (
          <EmployerSaveSearchModal
            editItem={filters}
            userProfile={userProfile}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            searchName={keyword}
          />
        )}
        <EmployerResumePreview
          modalOpen={prevOpen}
          setModalOpen={setPrevOpen}
          candidateId={selectCandidate}
          userProfile={userProfile}
          onNextProfile={handleNextProfile}
          onPrevProfile={handlePrevProfile}
          isPrevDisable={isPrevDisable}
          isNextDisable={isNextDisable}
          saveProfile={data => saveResumeHandler(data, true)}
          unsaveProfile={data => saveResumeHandler(data, false)}
          isSaved={isSavedProfile}
        />
      </div>
    </>
  )
}
export default ResumeSearchComponent
